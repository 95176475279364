<template>
    <BSidebar
        :id="id"
        title="Фильтр"
        style="width: 420px;"
        backdrop
        shadow
    >

        <template #default="{ hide }">
            <div class="px-3 py-4">
                <b-form @submit.prevent="">

                    <b-row>

                        <b-col
                            v-for="(filterItem, key) in filter"
                            :key="key"
                            cols="12"
                        >

                            <b-form-group
                                :label="getLabel(key)"
                            >

                                <DateFromTo
                                    v-if="isDatePicker(key)"
                                    v-model="filter[key]"
                                />

                                <b-form-select
                                    v-else-if="isStatusSelect(key)"
                                    v-model="filter[key]"
                                    :options="statuses"
                                />

                                <b-form-select
                                    v-else-if="key === 'provider_contract_id'"
                                    v-model="filter[key]"
                                    :options="providersContractsOptions"
                                />

                                <b-form-select
                                    v-else-if="key === 'work_agreement_id'"
                                    v-model="filter[key]"
                                    :options="workAgreementsOptions"
                                />

                                <b-form-select
                                    v-else-if="key === 'organization_id'"
                                    v-model="filter[key]"
                                    :options="organizationsOptions"
                                />

                                <b-form-select
                                    v-else-if="key === 'work_type'"
                                    v-model="filter[key]"
                                    :options="workTypesOptions"
                                />

                                <b-form-select
                                    v-else-if="key === 'object_id'"
                                    v-model="filter[key]"
                                    :options="[{value: '', text: 'Все'}, ...objectsOptions]"
                                />

                                <b-form-select
                                    v-else-if="key === 'sub_object_id'"
                                    v-model="filter[key]"
                                    :options="subObjectsOptions"
                                />

                                <b-form-input
                                    v-else-if="key === 'amount_without_vat'"
                                    v-model.trim="filter[key]"
                                    v-mask="decimalMask"
                                    trim
                                />

                                <b-form-select
                                    v-else-if="isContrAgentsSelect(key)"
                                    v-model="filter[key]"
                                    :options="contrAgentsOptions"
                                />

                                <b-form-input
                                    v-else
                                    v-model.trim="filter[key]"
                                    trim
                                />

                            </b-form-group>

                        </b-col>

                    </b-row>

                    <div class="d-flex mt-4">

                        <b-button
                            type="submit"
                            variant="primary"
                            class="px-4"
                            @click="applyFilter(hide)"
                        >Применить
                        </b-button>

                    </div>

                </b-form>
            </div>
        </template>

    </BSidebar>
</template>

<script>
import DateFromTo from "@/components/Form/DateFromTo"
import { mapGetters } from 'vuex'
import createNumberMask from "text-mask-addons/dist/createNumberMask"

const currencyMask = createNumberMask({
    prefix: '',
    allowDecimal: true,
    includeThousandsSeparator: true,
    allowNegative: false,
    thousandsSeparatorSymbol: ''
})

export default {
    name: 'FilterSidebar',
    components: {DateFromTo},
    model: {
        prop: 'filterData',
        event: 'change'
    },
    props: {
        id: String,
        filterData: Object
    },
    watch: {
        filter() {
            this.$emit('change', this.filter)
        },
        filterData() {
            this.setFilter()
        }
    },
    computed: {
        ...mapGetters({
            providersContractsOptions: 'getProvidersContractsOptions',
            workAgreementsOptions: 'getWorkAgreementsOptions',
            organizationsOptions: 'getOrganizationsOptions',
            workTypesOptions: 'getWorkTypesOptions',
            objectsOptions: 'getObjectsOptions',
            contrAgentsOptions: 'getContrAgentsOptions',
            statuses: 'getStatuses'
        }),
        subObjectsOptions() {
            const objects = this.$store.state.referenceBooks.objects
            let res = [{text: 'Все', value: ''}]
            objects?.data?.forEach(object => {
                if (object.sub_objects && object.sub_objects.length) {
                    res.push(...object.sub_objects.map(subobject => ({
                        text: subobject.name,
                        value: subobject.id
                    })))
                }
            })
            return res
        }
    },
    data: () => ({
        decimalMask: currencyMask,
        filter: {}
    }),
    methods: {
        setFilter() {
            this.filter = this.filterData
        },
        isDatePicker (key) {
          return key === 'order_date' ||
              key === 'deadline_date' ||
              key === 'date' ||
              key === 'work_agreement_date' ||
              key === 'contract_date' ||
              key === 'provider_contract_date' ||
              key === 'delivery_time' ||
              key === 'consignment_date' ||
              key === 'payment_order_date'
        },
        isStatusSelect (key) {
            return key === 'customer_status' ||
                key === 'provider_status' ||
                key === 'organization_status' ||
                key === 'status'
        },
        isContrAgentsSelect (key) {
            return key === 'provider_id' ||
                key === 'contractor_id'
        },
        getLabel(key) {
            const text = {
                number: 'Номер',
                order_date: 'Дата заказа',
                deadline_date: 'Срок исполнения заказа',
                customer_status: 'Статус согласования с заказчиком',
                provider_status: 'Статус согласования с поставщиком',
                provider_contract_id: 'Номер договора поставки',
                work_agreement_id: 'Номер договора подряда',
                organization_id: 'Филиал',
                work_type: 'Вид работ',
                object_id: 'Объект',
                sub_object_id: 'Подобъект',
                amount_without_vat: 'Сумма',
                date: 'Дата',
                provider_id: 'Поставщик',
                contractor_id: 'Подрядчик',
                work_agreement_date: 'Дата договора подряда',
                contract_stage: 'Этап',
                contract_number: 'Номер договора',
                contract_date: 'Дата договора',
                type: 'Тип запроса',
                organization_status: 'Статус',
                object_name: 'Название объекта',
                order_number: 'Номер заказа',
                provider_name: 'Поставщик',
                status: 'Статус',
                mnemocode: 'Мнемокод',
                nomenclature_name: 'Номенклатура',
                price_without_vat: 'Цена',
                delivery_time: 'Срок поставки',
                delivery_address: 'Адрес поставки',
                consignment_number: 'Номер',
                consignment_date: 'Дата',
                country: 'Страна',
                cargo_custom_declaration: 'ГДТ',
                declaration: 'Декларация',
                object: 'Название объекта',
                organization: 'Филиал',
                work_agreement_number: 'Номер договора подряда',
                payment_order_date: 'Дата ПП',
                provider_contract_date: 'Дата договора поставки'
            }
            return text[key] || ''
        },
        applyFilter(hide) {
            hide()
            this.$emit('apply', this.filter)
        }
    },
    created() {
        this.setFilter()
    }
}
</script>

<style
    lang="sass"
    scoped
>

</style>
